<template>
  <v-container>
    <h1 class="display-1 font-weight-bold mx-1 mb-3">Our Artists</h1>
    <v-row>
    <v-col v-for="artist of artists" :key="artist.artistCode" xl= "2" lg="3" sm="4" cols="12" class="d-flex flex-column">
      <v-card class="mx-2 my-2 d-flex flex-column flex-grow-1">
        <v-card-title class="justify-center" >{{artist.name}}</v-card-title>
        <v-card-text class="d-flex flex-column">
          <router-link :to="'artist/' + artist.artistCode">
            <v-img
              style="transparency: 100%; border: 1px solid silver"
              class="v-clickable"
              max-height="300px"
              max-width="300px"
              :src="`${imageUrl}/300px/${
                artist.signatureImage || artist.products[0]?.artworkImage
              }`"
            ></v-img>
          </router-link>
        </v-card-text>
      </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ArtistApi from "@/api/public/artist";

export default {
  components: {},
  data() {
    return {
      artists: null,
      headers: [
        {
          text: "Artist",
          align: "start",
          sortable: false,
          value: "artist",
        },
        { text: "Title", value: "title" },
        { text: "Category", value: "category" },
      ],
      imageUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  computed: mapState({
    ...mapGetters(["isLoggedIn"]),
  }),
  mounted: async function () {},
  methods: {
    goToLink(artist) {
      // this.isLoggedIn ? this.goToProducts(artist.name) : this.goToArtist(artist.artistCode)
      this.goToArtist(artist.artistCode);
    },
    goToProducts(artist) {
      const obj = { artist };
      obj.artist = [artist];
      this.$store.commit("filters/setFilters", obj);
      this.$router.push("/products").catch((err) => {
        return err !== null;
      });
    },
    goToArtist(artistCode) {
      this.$router.push(`/artist/${artistCode}`).catch((err) => {
        return err !== null;
      });
    },
  },
  async created() {
    ArtistApi.loadArtists().then((results) => {
      this.artists = results.data;
    });
  },
};
</script>
<style scoped>
.custom-tooltip {
  opacity: 1 !important;
}
</style>
