<template>
  <v-main app>
    <Artists />
  </v-main>
</template>

<script>
// @ is an alias to /src
import Artists from "@/views/Public/Artists/Artists.vue";

export default {
  metaInfo: {
    title: "The best of original Scottish artists",
  },
  components: {
    Artists,
  },
};
</script>
