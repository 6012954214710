import { render, staticRenderFns } from "./Artists.vue?vue&type=template&id=35310bb5&scoped=true"
import script from "./Artists.vue?vue&type=script&lang=js"
export * from "./Artists.vue?vue&type=script&lang=js"
import style0 from "./Artists.vue?vue&type=style&index=0&id=35310bb5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35310bb5",
  null
  
)

export default component.exports